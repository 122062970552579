import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


//Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HttpClientModule } from '@angular/common/http';

import { DatePipe } from '@angular/common';
// import { UserService } from '../providers/user-service/user-service';

import { MomentModule } from 'ngx-moment';
import { DateFnsModule } from 'ngx-date-fns';
import { ReactiveFormsModule } from '@angular/forms';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { SocketioService } from './socketio.service';

const firebaseConfig = {
  apiKey: "AIzaSyBx4KZ4LOJyDehSr7RpYdJggcScYuCPWA8",
  authDomain: "skyrunning-ef4a7.firebaseapp.com",
  databaseURL: "https://skyrunning-ef4a7.firebaseio.com",
  projectId: "skyrunning-ef4a7",
  storageBucket: "skyrunning-ef4a7.appspot.com",
  messagingSenderId: "205982119948",
  appId: "1:205982119948:android:2bec4445c8867dd1"
};




@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    HttpClientModule ,
    DateFnsModule.forRoot(),
    ReactiveFormsModule,
  ],
  exports:[
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    AndroidPermissions,SocketioService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}





